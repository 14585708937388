import type { Folder, PermissionProperties } from '~/types'

export const isFullAccessPermission = ({
  can_see,
  can_read,
  can_write,
  can_delete,
}: PermissionProperties) => can_see && can_read && can_write && can_delete

export const isViewAccessPermission = (permission: PermissionProperties) =>
  !isFullAccessPermission(permission) && permission.can_see && permission.can_read

export const isPermissionWithoutAccess = (permission: PermissionProperties) =>
  !isFullAccessPermission(permission) && !isViewAccessPermission(permission)

export const isLimitedAccessPermission = ({ can_see, can_read }: PermissionProperties) =>
  can_see && !can_read

export const isLimitedFolderAccessPermission = (folder: Maybe<Folder>) => {
  /*
    TODO FolderData's own_permissions should not be optional
    and this functions should not be folder-specific
  */
  if (!folder)
    return true

  const { can_see = false, can_read = false } = folder.own_permissions ?? {}

  return isLimitedAccessPermission({
    can_see,
    can_read,
  } as PermissionProperties)
}
